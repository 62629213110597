import { Locale, SiteMenuCategories, SiteMenuItem } from '@/types';

export function keys<T>(obj: object): T[] {
  return Object.keys(obj) as T[];
}

export const isEng = (locale: Locale) => locale === Locale.EN;

const EXLUDED_SLUG = [/* SiteMenuCategories.Places */'Greenhouse exposition', 'Greenhouses', 'Expositions', 'Interactive map', 'Birds in the garden'/* SiteMenuCategories.Places */]

export const sortSiteMenu = (locale: Locale, siteMenu?: SiteMenuItem[]): SiteMenuItem[] => {
  return !siteMenu
  ? []
  : siteMenu
      .filter(({ dropdown, slug }) => /* isEng(locale) ? slug !== SiteMenuCategories.Places && !!slug :  */ !!slug)
      .map((menuItem) => {
        return {
          ...menuItem,
          dropdown: [...(menuItem.dropdown
            .sort((a, b) => a.menu_order - b.menu_order)
            .filter(item => !EXLUDED_SLUG.includes(item.name)))],
        }
      });
}
