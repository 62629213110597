'use client';
import { useEffect } from 'react';

import { useLocale, toIsoLocale } from '@/hooks/useLocale';

export default function Gleap() {
  const { currentLocale } = useLocale();

  useEffect(() => {
    const initializeGleapModule = async () => {
      if (!process.env.NEXT_PUBLIC_GLEAP_TOKEN) return null;

      const GleapModule = (await import('gleap')).default;
      if (!GleapModule.getInstance().initialized) {
        GleapModule.setLanguage(toIsoLocale(currentLocale));
        GleapModule.initialize(process.env.NEXT_PUBLIC_GLEAP_TOKEN);
      }
    };

    initializeGleapModule();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}
