import { OpenGraphMedia } from 'next-seo/lib/types';

import { IPage } from '@/types/types';
import { BlogDetails } from './types/Pages';

export enum SiteMenuCategories {
  Blog = 'blog',
  Events = 'events',
  Help = 'help',
  Info = 'info',
  Places = 'places',
  Policies = 'policies',
}

export enum Locale {
  UA = 'ua',
  EN = 'en',
}
export enum DropdownCategories {
  LanguageSwitcher = 'languageSwitcher',
}
export interface ISiteMenuDropdown {
  name: string;
  menu_order: number;
  page_slug: string;
  blog_slug: string;
}

export type SiteMenuItem = {
  name: string;
  dropdown: ISiteMenuDropdown[];
  slug: SiteMenuCategories;
};

export interface IPageWithMenuProps {
  siteMenu: SiteMenuItem[];
}

export interface IPageDetailsProps extends IPageWithMenuProps {
  pageDetails: IPage | undefined;
  params: { [key: string]: any}
}

export interface IBlogDetailsProps extends IPageWithMenuProps {
  pageDetails: BlogDetails | undefined;
}

export type SeoImage = OpenGraphMedia;

export interface ISEOOptions {
  title: string;
  description: string;
  images?: SeoImage[];
  thumbnail?: string;
}

export type HeaderDropdowns = SiteMenuCategories | DropdownCategories;

export type HeaderDropdownsState = Record<HeaderDropdowns, boolean>;

export interface OptimizedImageSizes {
  small: string;
  medium: string;
  large: string;
}

export interface IBanner extends OptimizedImageSizes {
  src: string;
  alt: string;
}
