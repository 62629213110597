import { useRouter } from 'next/router';
import { useMemo } from 'react';

import nextI18nextConfig from '../../next-i18next.config';

import { isEng } from '@/helpers/common';
import { Locale } from '@/types';

interface IUseLocale {
  currentLocale: Locale;
  isEnLocale: boolean;
}

export function toIsoLocale(locale: Locale | string | string[]): string {
  const toCheck = Array.isArray(locale) ? locale[0] : locale;
  return toCheck === 'ua' ? 'uk' : toCheck;
}

export const useLocale = (): IUseLocale => {
  const router = useRouter();
  const currentLocale = useMemo<Locale>(() => {
    const locale = router?.query?.locale || nextI18nextConfig.i18n.defaultLocale;
    return locale as Locale;
  }, [router]);
  const isEnLocale = useMemo<boolean>(() => isEng(currentLocale), [currentLocale]);
  return { currentLocale, isEnLocale };
};
