// types
export type {
  IPlantDetails,
  IPlantImage,
  IBlogArticle,
  IPage,
  INews,
  ITicket,
  ITicketSectionInfo,
  IInteractiveMapPlant,
  DepartmentPageSlug,
} from './types';

// style types
export { Colors } from '@/types/style';

// common types
export { SiteMenuCategories, DropdownCategories, Locale } from './common';
export type {
  SiteMenuItem,
  ISiteMenuDropdown,
  IPageWithMenuProps,
  IPageDetailsProps,
  ISEOOptions,
  SeoImage,
  HeaderDropdowns,
  HeaderDropdownsState,
  IBanner,
  OptimizedImageSizes,
} from './common';
