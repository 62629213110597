const { DefaultLocale, Locales } = require('./config');

module.exports = {
  i18n: {
    locales: Locales,
    defaultLocale: DefaultLocale,
    localeDetection: true,
    /* fallbackLng: {
      uk: ['ua'],
    },
    nonExplicitSupportedLngs: true, */
  },
};
