const Locales = ['en', 'ua'];
const DefaultLocale = 'ua';

const PUBLIC_IMAGES = {
  QR_IMAGE: '/qr-image.webp',
  QR_CODE: '/qr-code.svg',
  NEWS_BG: '/NewsBg.webp',
  FOOTER_BANNER: '/footer-banner-phone.webp',
  PLAN_YOUR_VISIT: '/plan-visit-plants.webp',
  ARBORETUM: '/arboretum.webp',
  EXPOSITIONS: '/expositions.webp',
  GREENHOUSE_EXPO: '/greenhouse-exposition.webp',
  GREENHOUSE: '/greenhouses.webp',
  HISTORICAL_MONUMENTS: '/historical-monuments-and-art-objects.webp',
  MOCK_ARTICLE: '/mock-article-image.jpg',
  GARDEN_MAP: '/garden-map.webp',
  LEAF_LEFT: '/leafLeft.svg',
  LEAF_RIGHT: '/leafRight.svg',
  INSTAGRAM_COLLECTION: '/instagram-collection.svg',
  INSTAGRAM_VIDEO: '/instagram-video.svg',
  VOLUNTEERS: '/volunteers.webp',
};

const ACTIVITIES_IMAGES = {
  Bird: '/activities/bird.svg',
  BuyFlowers: '/activities/buy-flowers.svg',
  DontCatch: '/activities/dont-catch.svg',
  DontDrink: '/activities/dont-drink.svg',
  DontEat: '/activities/dont-eat.svg',
  DontTrash: '/activities/dont-trash.svg',
  DontWalk: '/activities/dont-walk.svg',
  Excursion: '/activities/excursion.svg',
  Fire: '/activities/fire.svg',
  Music: '/activities/music.svg',
  NoBicycle: '/activities/no-bicycle.svg',
  NoCutting: '/activities/no-cutting.svg',
  NoDrones: '/activities/no-drones.svg',
  NoSmoking: '/activities/no-smoking.svg',
  Photo: '/activities/photo.svg',
  Rest: '/activities/rest.svg',
  ManWalking: '/activities/walking-men.svg',
  WithoutAnimals: '/activities/without-animals.svg',
};

const HOME_SERVICES_IMAGES = {
  BookIcon: '/home-services/book.svg',
  ExchangeIcon: '/home-services/exchange.svg',
  ForrestIcon: '/home-services/forrest.svg',
  HandsIcon: '/home-services/hands.svg',
  MagGlassIcon: '/home-services/magnifying-glass.svg',
  MapIcon: '/home-services/map.svg',
};

const INSTAGRAM_NAME = '@botsad_dnipro';
const INSTAGRAM_LINK = 'https://www.instagram.com/botsad_dnipro/';
const FACEBOOK_LINK = 'https://www.facebook.com/botgarddnepr';
const GO_WOMBAT_LINK = 'https://gowombat.team/';
const PHONE = '+380 95 382 21 30';
const PHONE_HREF = `tel:${PHONE}`;
const GOOGLE_MAPS_COORDINATES = {
  lat: 48.4367916,
  lng: 35.0436205,
};
const GOOGLE_MAPS_LINK = `https://www.google.com/maps/search/?api=1&query=${GOOGLE_MAPS_COORDINATES.lat},${GOOGLE_MAPS_COORDINATES.lng}`;

module.exports = {
  Locales,
  DefaultLocale,
  PUBLIC_IMAGES,
  ACTIVITIES_IMAGES,
  HOME_SERVICES_IMAGES,
  INSTAGRAM_NAME,
  INSTAGRAM_LINK,
  FACEBOOK_LINK,
  PHONE,
  PHONE_HREF,
  GO_WOMBAT_LINK,
  GOOGLE_MAPS_LINK,
  GOOGLE_MAPS_COORDINATES,
};
